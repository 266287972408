'use client';
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { usePathname } from "next/navigation";

gsap.registerPlugin(ScrollTrigger);

const WorkHard = ({ data }) => {
    const [deviceWidth, setDeviceWidth] = useState(0);

    const location = usePathname();

    useEffect(() => {
        function handleWidth() {
            setDeviceWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleWidth);
        handleWidth();

        return () => {
            window.removeEventListener("resize", handleWidth);
        };
    }, []);

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".work-hard-section",
                start: "top 50%",
                end: "bottom 0",
                scrub: true,
            },
        });

        tl.fromTo(
            ".work-hard-text",
            {
                yPercent: 60, // Start from below the viewport
                opacity: 0, // Initially invisible
            },
            {
                yPercent: -40,
                opacity: 1, // Fully visible
                duration: 0.05,
                ease: "power1.out",
            },
            "<"
        );

        return () => {
            // Clean up GSAP animations
            tl.kill();
        };
    }, [data, location]);

    return (
        <StyledComponent className="work-hard-section">
            {deviceWidth > 767 ? (
                <img
                    src={data?.images?.list?.find(f=>f?.desktop === 'on')?.full_path}
                    alt="hills"
                    className="work-hard-image"
                />
            ) : (
                <img
                    src={data?.images?.list?.find(f=>f?.mobile === 'on')?.full_path ?
                        data?.images?.list?.find(f=>f?.mobile === 'on')?.full_path :
                        data?.images?.list?.find(f=>f?.desktop === 'on')?.full_path
                }
                    className="work-hard-image"
                />
            )}

            <Container className="pt-150 work-hard-text">
                <Row>
                    <Col md={{ offset: 4, span: 8}} sm={{offset:4, span: 8}}>
                        <h2 className="h2-top">{data?.section_data?.subtitle}</h2>
                        <h2 className="h2-bottom">{data?.section_data?.short_desc}</h2>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    position: relative;
    background-color: #ffffff;
    overflow: hidden;
    width: 100%;
    height: 850px;

    img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 2;
    }

    .container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        
        .row{
            width: 100%;
        }
        .h2-top, .h2-bottom {
            font-size: 80px;
            line-height: 50px;
            letter-spacing: -2.4px;
            font-weight: 400;
            color: #137ec3;
            margin-left: -60px;
            &:last-child {
                padding-top: 50px;
                padding-left: 90px;
                font-size: 80px;
                line-height: 50px;
                letter-spacing: -2.4px;
                color: #137ec3;
            }
            
            @media(max-width: 991px){
                font-size: 45px !important;
                line-height: 60px !important;
                
                &:last-child{
                    padding-left: 0;
                    font-size: 45px !important;
                    line-height: 60px !important;
                }
            }
        }
    }

    @media (max-width: 767px) {
        .container {
            .h2-top, .h2-bottom {
                padding-left: 65px;
                font-size: 50px !important;
                line-height: 50px !important;
                letter-spacing: -1.2px;
                &:last-child {
                    padding-left: 95px;
                    padding-top: 0;
                    font-size: 40px;
                    line-height: 50px;
                    letter-spacing: -1.2px;
                }
            }
        }
    }

    @media (max-width: 991px) {
    }
`;

export default WorkHard;
