"use client";
import styled from 'styled-components';
import {useEffect, useState} from "react";

const ImageParallax = ({src, srcSm, alt, objectFit, position,height, width, banner, left, margin, right, top, bottom}) => {

    const [currentSrc, setCurrentSrc] = useState(src);

    useEffect(() => {
        const updateImageSrc = () => {
            if (window.innerWidth <= 768 && srcSm) {
                setCurrentSrc(srcSm);
            } else {
                setCurrentSrc(src);
            }
        };

        // Set initial image source
        updateImageSrc();

        // Add a resize event listener to update the image source on window resize
        window.addEventListener('resize', updateImageSrc);

        // Clean up the event listener on component unmount
        return () => window.removeEventListener('resize', updateImageSrc);
    }, [src, srcSm]);

    return (
        <StyledImg className='global-image' objectFit={objectFit} margin={margin} position={position} left={left}
                   right={right} top={top}
                   bottom={bottom} height={height} width={width}>
            <img data-speed={0.8} src={currentSrc} alt={alt || ''}/>
        </StyledImg>
    );
};

const StyledImg = styled.div`
  position: ${props => props.position || 'absolute'};
  height: ${props => props.height || '105%'};
  width: ${props => props.width || '100%'};
  top: ${props => props.top || 0};
  left: ${props => props.left || 0};
  bottom: ${props => props.bottom || 0};
  right: ${props => props.right || 0};
  margin: ${props => props.margin || 0};
    will-change: transform;

  img {
    width: 100%;
    height: 100%;
    object-fit: ${props => props.objectFit || 'cover'};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}`;

export default ImageParallax;