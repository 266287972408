"use client";
import styled from 'styled-components';
import {hover, text, title, title_ms60} from "@/styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";
import { motion, useInView } from 'framer-motion';
import * as React from 'react';

const Title = ({
                   text,
                   fontSize,
                   fontWeight,
                   color,
                   letterSpacing,
                   lineHeight,
                   textTransform,
                   margin,
                   padding,
                   borderColor,
                   varient,
                   center,
                   classname,
                   small_text,
                   align,
                   direction = 'up', // Added animation direction prop
                   staggerChildren = 0.1 // Added stagger effect timing
               }) => {
    const FADE_ANIMATION = {
        show: { opacity: 1, y: 0, transition: { type: 'spring' } },
        hidden: { opacity: 0, y: direction === 'down' ? -18 : 18 },
    };

    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <StyledTitle
            className={`title fade-up ${classname}`}
            fontSize={fontSize}
            fontWeight={fontWeight}
            color={color}
            lineHeight={lineHeight}
            LetterSpacing={letterSpacing}
            textTransform={textTransform}
            margin={margin}
            padding={padding}
            varient={varient}
            center={center}
            borderColor={borderColor}
            textAlign={align}
        >
            <motion.div
                ref={ref}
                initial="hidden"
                animate={isInView ? 'show' : ''}
                variants={{
                    hidden: {},
                    show: {
                        transition: {
                            staggerChildren: staggerChildren,
                        },
                    },
                }}
            >
                <motion.h2 variants={FADE_ANIMATION}>
                    {ReactHtmlParser(text)}
                </motion.h2>
            </motion.div>
        </StyledTitle>
    );
};

const StyledTitle = styled.div`
    margin: ${props => props.margin || '0px'};
    position: relative;
    width: 100%;
    font-family: ${title};
    text-align: ${props => props?.center ? 'center' : ''};
    padding: ${p => p.padding};

    h2 {
        color: ${props => props.color || ''};
        text-align: ${props => props.textAlign || ''};
    }

    @media (max-width: 767px) {
        margin-bottom: 40px;
        padding: 0;
    }
`;

export default Title;