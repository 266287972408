"use client";
import styled from 'styled-components';
import Link from "next/link";


const Button = ({
                    onSubmit,
                    onClick,
                    text,
                    src,
                    img,
                    hoverImg,
                    fontSize,
                    fontWeight,
                    color,
                    letterSpacing,
                    lineHeight,
                    margin,
                    background,
                    borderRadius,
                    border,
                    width,
                    height,
                    hoverBackground,
                    target,
                    borderColor,
                    hoverColor,
                    svgColor
                }) => {


    return (
        <StyledBtn className={`dc-btn`}
                   fontSize={fontSize}
                   fontWeight={fontWeight}
                   color={color}
                   background={background}
                   lineHeight={lineHeight}
                   letterSpacing={letterSpacing}
                   margin={margin}
                   border={border}
                   img={img}
                   borderRadius={borderRadius}
                   width={width}
                   hoverImg={hoverImg}
                   hoverBackground={hoverBackground}
                   height={height}
                   borderColor={borderColor}
                   target={target}
                   hoverColor={hoverColor}
                   onSubmit={onSubmit}
                   onClick={onClick}
        >
            {src ? (
                <Link href={src || '/'}>
                    <span> {text}  </span>
                    <svg xmlns="http://www.w3.org/2000/svg"  width="14" height="14" viewBox="0 0 14 14">
                        <defs>
                            <clipPath id="clip-path">
                                <rect id="Rectangle_5923" data-name="Rectangle 5923" width="14" height="14" transform="translate(7230 -1054.5)" fill="#fff"/>
                            </clipPath>
                        </defs>
                        <g id="Group_21929" data-name="Group 21929" transform="translate(-7230 1054.5)">
                            <g id="Mask_Group_525" data-name="Mask Group 525" clip-path="url(#clip-path)">
                                <g id="Group_21757" data-name="Group 21757" transform="translate(1244.199 4964.322) rotate(-45)">
                                    <line id="Line_3996" data-name="Line 3996" x2="5" y2="5" transform="translate(8488.5 -18.5)" fill="none" stroke={svgColor ? svgColor : '#fff'} stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_3997" data-name="Line 3997" y1="5" x2="5" transform="translate(8488.5 -13.5)" fill="none" stroke={svgColor ? svgColor : '#fff'} stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_3998" data-name="Line 3998" x1="10" transform="translate(8483.5 -13.5)" fill="none" stroke={svgColor ? svgColor : '#fff'} stroke-linecap="round" stroke-width="1"/>
                                </g>
                            </g>
                        </g>
                    </svg>

                </Link>
            ) : (
                <a target={target || '_self'}>
                    <span>{text}</span>
                    <svg xmlns="http://www.w3.org/2000/svg"  width="14" height="14" viewBox="0 0 14 14">
                        <defs>
                            <clipPath id="clip-path">
                                <rect id="Rectangle_5923" data-name="Rectangle 5923" width="14" height="14" transform="translate(7230 -1054.5)" fill="#fff"/>
                            </clipPath>
                        </defs>
                        <g id="Group_21929" data-name="Group 21929" transform="translate(-7230 1054.5)">
                            <g id="Mask_Group_525" data-name="Mask Group 525" clip-path="url(#clip-path)">
                                <g id="Group_21757" data-name="Group 21757" transform="translate(1244.199 4964.322) rotate(-45)">
                                    <line id="Line_3996" data-name="Line 3996" x2="5" y2="5" transform="translate(8488.5 -18.5)" fill="none" stroke={svgColor ? svgColor : '#fff'} stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_3997" data-name="Line 3997" y1="5" x2="5" transform="translate(8488.5 -13.5)" fill="none" stroke={svgColor ? svgColor : '#fff'} stroke-linecap="round" stroke-width="1"/>
                                    <line id="Line_3998" data-name="Line 3998" x1="10" transform="translate(8483.5 -13.5)" fill="none" stroke={svgColor ? svgColor : '#fff'} stroke-linecap="round" stroke-width="1"/>
                                </g>
                            </g>
                        </g>
                    </svg>

                </a>
            )}
        </StyledBtn>
    )
};

const StyledBtn = styled.div`
  &.dc-btn {
    margin: ${props => props.margin || '0'};
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '44'}px;
    cursor: pointer;

    a {
      display: flex;
      width: fit-content;
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: ${props => props.fontSize || '16'}px;
      font-weight: ${props => props.fontWeight || 500};
      margin: 0;
      line-height: ${props => props.lineHeight || '24'}px;
      letter-spacing: -0.48px;
      background-color: ${props => props.background || `#137EC3`};
      position: relative;
      border-radius: ${props => props.borderRadius || '24'}px;
      overflow: hidden;
      z-index: 0;
      padding: 14px 44px;
      box-sizing: border-box;
      text-transform: uppercase;
      gap: 8px;
      svg{
        transition: 0.3s all ease-in-out;
      }

      span {
        transition: color .3s ease;
        color: ${props => props.color || `#FFF`};
        position: relative;
        z-index: 2;
      }
      

      &:hover {
        transition: 0.75s all ease-in-out;
        span {
          color: ${props => props.hoverColor || ``};
        }

        svg{
          transform: rotate(45deg);
          transition: 0.3s all ease-in-out;
        }
      }

      &:focus {
        color: #222222;
      }
    }
  }

`;


export default Button;
